<template>
  <v-main class="shop" v-if="shop">
    <!-- carousel -->
    <v-row v-if="shop.image_paths && shop.image_paths.length > 0" align-content="start" justify="center" no-gutters class="mb-2">
      <v-carousel v-model="model" height="243" :show-arrows="true" :cycle="false" :continuous="false" hide-delimiters>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            x-small
          >
            <v-icon notranslate fas>fa-chevron-right</v-icon>
          </v-btn>
        </template>
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            x-small
          >
            <v-icon notranslate fas>fa-chevron-left</v-icon>
          </v-btn>
        </template>
        <div v-for="(item, i) in shop.image_paths" :key="i">
            <v-carousel-item :key="i" :src="item.image_path" @click.stop=""></v-carousel-item>
        </div>
      </v-carousel>
      <!-- <v-row justify="center" class="carouselCtrl">
        <v-icon size="6px" v-for="(item, i) in shop.image_paths" :key="i" @click="model = i" :class="[model == i ? 'active' : '']">{{model == i ? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-blank-circle-outline'}}</v-icon>
      </v-row> -->
    </v-row>
    <v-img v-else src="@/assets/noimg@2x.png" lazy-src="@/assets/noimg@2x.png" class="mb-4">
    </v-img>

    <div class="shop_details">

      <!-- お気に入り -->
      <v-row v-if="authorized">
        <v-col class="pa-0 text-right">
          <button type="button" class="b_fav on" v-show="shop.is_follow" @click="followSet(0, shop.id)">
            <span class="icon i_star"></span>
          </button>
          <button type="button" class="b_fav" v-show="!shop.is_follow" @click="followSet(1, shop.id)">
            <span class="icon i_star"></span>
          </button>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" class="pt-0 pb-0">
        <v-col cols="3" class="fontsize11 font-weight-bold">
          <img :src="category_img[shop.category_name]" style="height:60px; width:60px;">
        </v-col>
        <v-col cols="9">
          <v-row class="nl2br pb-1 fontsize13 font-weight-bold">
            {{shop.category_name}}
          </v-row>
          <v-row class="nl2br pb-1 fontsize15 font-weight-bold">
            {{shop.name.value}}
          </v-row>
        </v-col>
      </v-row>

      <!-- 予約ページ -->
      <v-row class="pa-0 text-center" v-if="shop.reserve_urls.length > 0">
        <v-col class="pa-0 text-right">
          <v-row align="center" style="padding-top:0.4rem;padding-bottom:0.4rem;" v-for="i in Math.ceil(shop.reserve_urls.length/2)" :key="i">
            <v-col class="pa-0 text-center" v-if="shop.reserve_urls[i*2-1]">
              <v-btn rounded class="maincolor-bg default_button5" @click.stop="toHref(shop.reserve_urls[i*2-2].url)">
                {{shop.reserve_urls[i*2-2].text}}
              </v-btn>
            </v-col>
            <v-col class="pa-0 text-center" v-if="!shop.reserve_urls[i*2-1]">
              <v-btn rounded class="maincolor-bg default_button6" @click.stop="toHref(shop.reserve_urls[i*2-2].url)">
                {{shop.reserve_urls[i*2-2].text}}
              </v-btn>
            </v-col>
            <v-col class="pa-0 text-center" v-if="shop.reserve_urls[i*2-1]">
              <v-btn rounded class="maincolor-bg default_button5" @click.stop="toHref(shop.reserve_urls[i*2-1].url)">
                {{shop.reserve_urls[i*2-1].text}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- 営業時間 -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.opening_hours.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.opening_hours.name}}</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.opening_hours.value}}</v-col>
      </v-row>

      <!-- 定休日 -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.holiday.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.holiday.name}}</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.holiday.value}}</v-col>
      </v-row>

      <!-- <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.area">
        <v-col cols="3" class="fontsize11 font-weight-bold">エリア</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.area}}</v-col>
      </v-row> -->

      <!-- 住所 -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.address1.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.address1.name}}</v-col>
        <v-col cols="8" class="nl2br fontsize11 pr-1">〒 {{shop.zipcode.value}}</v-col>
        <v-col cols="3" class="fontsize11 font-weight-bold"></v-col>
        <v-col cols="8" class="nl2br fontsize11 pr-1" @click="toMap()">{{shop.prefecture}} {{shop.address1.value}} {{shop.address2.value}}</v-col>
        <v-col cols="1">
          <svg class="svg_maincolor" @click="toMap()"
            xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 32 32"><g transform="translate(-319 -1125)"><g transform="translate(319 1125)" fill="none" stroke="#ff00b1" stroke-width="1"><circle cx="15" cy="15" r="15" stroke="none"/><circle cx="15" cy="15" r="14.5" fill="none"/></g><g transform="translate(315.117 1132)"><path d="M18.773,0a5.9,5.9,0,0,0-5.89,5.891A5.746,5.746,0,0,0,13.4,8.3a37.14,37.14,0,0,0,5.127,7.591.328.328,0,0,0,.5,0A37.159,37.159,0,0,0,24.149,8.3a5.743,5.743,0,0,0,.515-2.4A5.9,5.9,0,0,0,18.773,0Zm0,8.95a3.06,3.06,0,1,1,3.06-3.06A3.063,3.063,0,0,1,18.773,8.95Z" transform="translate(0)" fill="#ff00b1"/></g></g></svg>
        </v-col>
      </v-row>
      <!-- その他（アクセス） -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.other.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.other.name}}</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.other.value}}</v-col>
      </v-row>

      <!-- 電話番号 -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.tel.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.tel.name}}</v-col>
        <v-col cols="8" class="nl2br fontsize11"><a style="color:#333;text-decoration:none;" :href="'tel:'+shop.tel.value">{{shop.tel.value}}</a></v-col>
        <v-col cols="1">
          <a :href="'tel:'+shop.tel.value">
            <svg class="svg_maincolor" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 32 32"><g transform="translate(-329 -715)"><g transform="translate(337 722.812)"><g transform="translate(0 0.188)"><path d="M13.993,11.23a.677.677,0,0,1-.207.593l-1.971,1.956a1.109,1.109,0,0,1-.348.252,1.439,1.439,0,0,1-.422.133l-.089.007q-.074.007-.193.007a6.832,6.832,0,0,1-.912-.1,6.922,6.922,0,0,1-1.541-.474,12.938,12.938,0,0,1-2.068-1.134A15.415,15.415,0,0,1,3.782,10.4,16.241,16.241,0,0,1,2.063,8.443a13.939,13.939,0,0,1-1.1-1.727A9.3,9.3,0,0,1,.343,5.279,7.627,7.627,0,0,1,.062,4.167,3.671,3.671,0,0,1,0,3.434q.015-.267.015-.3a1.441,1.441,0,0,1,.133-.422A1.111,1.111,0,0,1,.4,2.367L2.374.4A.653.653,0,0,1,2.848.188.553.553,0,0,1,3.189.3a1.03,1.03,0,0,1,.252.274L5.027,3.582A.721.721,0,0,1,5.1,4.1a.924.924,0,0,1-.252.474L4.123,5.3a.247.247,0,0,0-.052.1.366.366,0,0,0-.022.111,2.676,2.676,0,0,0,.267.711,6.53,6.53,0,0,0,.548.867A9.627,9.627,0,0,0,5.916,8.265,9.817,9.817,0,0,0,7.1,9.325a6.847,6.847,0,0,0,.867.556A2.3,2.3,0,0,0,8.5,10.1l.185.037a.353.353,0,0,0,.1-.022.247.247,0,0,0,.1-.052l.845-.86a.908.908,0,0,1,.622-.237.774.774,0,0,1,.4.089h.015l2.86,1.69A.746.746,0,0,1,13.993,11.23Z" transform="translate(0 -0.188)" fill="#ff00b1"/></g></g><g transform="translate(329 715)" fill="none" stroke="#ff00b1" stroke-width="1"><circle cx="15" cy="15" r="15" stroke="none"/><circle cx="15" cy="15" r="14.5" fill="none"/></g></g></svg>
          </a>
        </v-col>
      </v-row>

      <!-- お店の特徴 -->
      <v-row no-gutters align="center" class="py-3">
        <v-col class="fontsize11 font-weight-bold">
          お店の特徴
        </v-col>
      </v-row>
      <!-- キャッチコピー -->
      <v-row v-if="shop.catch_copy">
        <v-col class="nl2br px-0 fontsize13 font-weight-bold" style="padding-top:0;">
          {{shop.catch_copy}}
        </v-col>
      </v-row>
      <!-- 店舗説明文 -->
      <v-row class="row-shop-detail" v-if="shop.description">
        <v-col class="nl2br px-0 fontsize11">
          {{shop.description}}
        </v-col>
      </v-row>

      <!-- 平均予算（目安料金） -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.price.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.price.name}}</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.price.value}}</v-col>
      </v-row>
      
      <!-- クレジット -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.credit_brand">
        <v-col cols="3" class="fontsize11 font-weight-bold" style="font-size: 10px !important; white-space: nowrap;">クレジットカード</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.credit_brand}}</v-col>
      </v-row>

      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.qr_brand">
        <v-col cols="3" class="fontsize11 font-weight-bold">QR決済</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.qr_brand}}</v-col>
      </v-row>

      <!-- 座席数 -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.seats_number.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.seats_number.name}}</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.seats_number.value}}</v-col>
      </v-row>

      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.max_capacity">
        <v-col cols="3" class="fontsize11 font-weight-bold">宴会最大人数</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.max_capacity}}</v-col>
      </v-row>
      
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.smoking">
        <v-col cols="3" class="fontsize11 font-weight-bold">禁煙・喫煙</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.smoking}}</v-col>
      </v-row>

      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.pet_friendly">
        <v-col cols="3" class="fontsize11 font-weight-bold">ペット同伴</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.pet_friendly}}</v-col>
      </v-row>

      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.foreign_menu">
        <v-col cols="3" class="fontsize11 font-weight-bold">外国語<br>メニュー</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.foreign_menu}}</v-col>
      </v-row>

      <!-- 駐車場 -->
      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.parking.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.parking.name}}</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.parking.value}}</v-col>
      </v-row>

      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.power_outlet">
        <v-col cols="3" class="fontsize11 font-weight-bold">電源</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.power_outlet}}</v-col>
      </v-row>

      <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.wifi_available">
        <v-col cols="3" class="fontsize11 font-weight-bold">Wifi</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.wifi_available}}</v-col>
      </v-row>

      <v-row no-gutters align="center" class="py-2 row-shop-detail">
        <v-col cols="3" class="fontsize11 font-weight-bold">特徴</v-col>
        <v-col cols="9" class="nl2br fontsize11">
          <div v-for="(id, i) in feature_icons" :key="i" class="tooltip">
            <img class="shop_feature_icon" :src="require('@/assets/shop_detail/feature' + id + '.png')">
            <div class="tooltip_description">
              {{feature_texts[id - 1]}}
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- <div v-if="shop.links">
        <v-row no-gutters align="center" class="pa-3" v-for="(link, index) in shop.links" :key="index">
          <v-col cols="3" class="fontsize11" style="color:#9D9D9D;">{{link.link_comment}}</v-col>
          <v-col cols="8" class="nl2br maincolor fontsize13 font-weight-bold" @click.stop="toHref(link.link_path)">{{link.link_path}}</v-col>
          <v-col cols="1" @click.stop="toHref(link.link_path)">
            <svg class="svg_maincolor" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 32 32"><g transform="translate(-329 -755)"><g transform="translate(329 755)" fill="none" stroke="#ff00b1" stroke-width="1"><circle cx="15" cy="15" r="15" stroke="none"/><circle cx="15" cy="15" r="14.5" fill="none"/></g><g transform="translate(277 761)"><path d="M3.75,11.857a.536.536,0,1,1,0-1.071H5.393V9.715H1.607A1.609,1.609,0,0,1,0,8.107V7.572H14v.536a1.609,1.609,0,0,1-1.607,1.607H8.607v1.071H10.25a.536.536,0,1,1,0,1.071ZM0,6.5V1.607A1.609,1.609,0,0,1,1.607,0H12.393A1.609,1.609,0,0,1,14,1.607V6.5Z" transform="translate(60 3)" fill="#ff00b1"/></g></g></svg>
          </v-col>
        </v-row>
      </div> -->

      <!-- 問い合わせ -->
      <!-- <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.email.value">
        <v-col cols="3" class="fontsize11 font-weight-bold">{{shop.email.name}}</v-col>
        <v-col cols="8" class="nl2br fontsize11"><a style="color:#333;text-decoration:none;" :href="'mailto:'+shop.email.value">{{shop.email.value}}</a></v-col>
        <v-col cols="1">
          <a :href="'mailto:'+shop.email.value">
            <svg class="svg_maincolor" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 32 32"><g transform="translate(-319 -1125)"><g transform="translate(319 1125)" fill="none" stroke="#ff00b1" stroke-width="1"><circle cx="15" cy="15" r="15" stroke="none"/><circle cx="15" cy="15" r="14.5" fill="none"/></g><g transform="translate(327 1080.18)"><g transform="translate(0 54.82)"><path d="M1.344,58.211q.266.187,1.6,1.113T4.992,60.75l.332.238q.254.184.422.3t.406.254a2.357,2.357,0,0,0,.449.211,1.238,1.238,0,0,0,.391.07h.016a1.239,1.239,0,0,0,.391-.07,2.359,2.359,0,0,0,.449-.211q.238-.141.406-.254t.422-.3q.254-.184.332-.238l3.656-2.539a3.711,3.711,0,0,0,.953-.961A2.063,2.063,0,0,0,14,56.07a1.261,1.261,0,0,0-1.25-1.25H1.25a1.116,1.116,0,0,0-.926.406A1.577,1.577,0,0,0,0,56.242a1.825,1.825,0,0,0,.43,1.066A3.878,3.878,0,0,0,1.344,58.211Z" transform="translate(0 -54.82)" fill="#ff00b1"/><path d="M13.219,185.117q-2.562,1.734-3.89,2.7-.445.328-.723.512a4.461,4.461,0,0,1-.738.375,2.248,2.248,0,0,1-.859.191H6.992a2.249,2.249,0,0,1-.859-.191,4.463,4.463,0,0,1-.738-.375q-.277-.183-.723-.512-1.055-.773-3.883-2.7a4.138,4.138,0,0,1-.789-.68v6.2a1.2,1.2,0,0,0,.367.883,1.2,1.2,0,0,0,.883.367h11.5A1.254,1.254,0,0,0,14,190.64v-6.2A4.308,4.308,0,0,1,13.219,185.117Z" transform="translate(0 -180.89)" fill="#ff00b1"/></g></g></g></svg>
          </a>
        </v-col>
      </v-row> -->

      <!-- <div v-if="shop.fb_link_path || shop.twitter_link_path || shop.instragram_link_path">
        <v-divider class="px-3 my-3"></v-divider>

        <h2 class="text-center body-2 font-weight-bold mb-2">SNS</h2>
        <div class="px-3">
        <v-row dense align="center" justify="center" class="mb-10">
          <v-col cols="4" v-if="shop.fb_link_path">
            <v-btn small class="px-2 w100 maincolor" rounded outlined @click.stop="toHref(shop.fb_link_path)">facebook <v-icon x-small right class="ml-1 maincolor">fab fa-facebook-f</v-icon></v-btn>
          </v-col>
          <v-col cols="4" v-if="shop.twitter_link_path">
            <v-btn small class="px-2 w100 maincolor" rounded outlined @click.stop="toHref(shop.twitter_link_path)">twitter <v-icon x-small right class="ml-1 maincolor">fab fa-twitter</v-icon></v-btn>
          </v-col>
          <v-col cols="4" v-if="shop.instragram_link_path">
            <v-btn small class="px-2 w100 maincolor" rounded outlined @click.stop="toHref(shop.instragram_link_path)">instagram <v-icon x-small right class="ml-1 maincolor">fab fa-instagram-square</v-icon></v-btn>
          </v-col>
        </v-row>
        </div>
      </div> -->

      <!-- <v-row no-gutters align="center" class="py-2 row-shop-detail" v-if="shop.fax">
        <v-col cols="3" class="fontsize11 font-weight-bold">FAX番号</v-col>
        <v-col cols="9" class="nl2br fontsize11">{{shop.fax}}</v-col>
      </v-row> -->
      <!-- <v-row no-gutters align="center" class="pa-3" v-if="shop.line_tag">
        <v-col cols="3" class="fontsize11" style="color:#9D9D9D;">LINEで送るタグ</v-col>
        <v-col cols="9" class="nl2br maincolor fontsize13 font-weight-bold">{{shop.line_tag}}</v-col>
      </v-row> -->
    </div>

    <v-overlay :value="loading" z-index=9999>
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      maincolor: this.$root.maincolor,
      shop: null,
      loading: false,
      model: 0,
      feature_icons: [],
      feature_texts: [
        '駅直結',
        '会食用個室',
        '大型個室',
        'お座敷',
        '掘り炬燵',
        '静かな席',
        'ソファー席',
        '窓際席',
        '忘年会',
        '飲み放題',
        '女子会',
        'テイクアウト',
        '弁当予約',
        'お一人様',
        'お子様連れ',
        'お食事のみOK',
        '夜景ビュー',
        'やまやのランチ',
        '明太子おかわり自由',
        '一人もつ鍋',
      ],
      authorized : false,
      category_img: {
        "博多もつ鍋やまや": require("@/assets/shop_logo/logo_motsunabe.svg"),
        "めんたいBASE": require("@/assets/shop_logo/logo_mentaibase.jpg"),
        "焼肉やまや": require("@/assets/shop_logo/logo_yakiniku.jpg"),
        "博多やきとりNICK": require("@/assets/shop_logo/logo_nick.jpg"),
        "博多天ぷらやまや": require("@/assets/shop_logo/logo_hakatatenpurayamaya.png"),
        "ごはんとわたし": require("@/assets/shop_logo/logo_gohantowatashi.jpg"),
        "博多めんたいやまや食堂": require("@/assets/shop_logo/logo_shokudo.jpg"),
        "博多屋台DELIやまや": require("@/assets/shop_logo/logo_deliyamaya.jpg"),
        "博多やまや": require("@/assets/shop_logo/logo_harajyuku.jpg"),
        "炉ばた焼やまや": require("@/assets/shop_logo/logo_robatayaki.jpg"),
        "別邸": require("@/assets/shop_logo/logo_bettei.jpg"),
        "カフェ＆バー": require("@/assets/shop_logo/logo_terrace.png"),
        "マルシェ": require("@/assets/shop_logo/logo_kajyukura.png"),
        "やまや総本店": require("@/assets/shop_logo/sohonten.png"),
        "Yamaya Factory Terrace": require("@/assets/shop_logo/yamayafactory.png"),
        "直営店": require("@/assets/shop_logo/logo_white.png"),
        "YAMAYA BASE": require("@/assets/shop_logo/yamayabase.png"),
        "果樹蔵": require("@/assets/shop_logo/logo_kajukura.png"),
      },
    }
  },
  async created() {
    this.getUrlParam()
    this.init = this.storageGet('*')

    if (!(this.getUrlGetParam('preview'))) {
      try {
          // アクセストークンチェックAPI
          const token_check_req = {}
          await this.apiCallCustomerPF('/access_token/check/' + this.init.device_uid, token_check_req)
          this.authorized = true
      } catch(e) {
          console.log(e)
      }
    }

    var req = { params:{
      'shop_id': this.init.shop_id,
    }}

    try {
      if (this.getUrlGetParam('preview')) {
        var preview_res = await this.apiCall('/user/shop/preview', req)
        this.$set(this, 'shop', preview_res.shop)
        if (!preview_res) return false;
      }else{
        var detail_res = await this.apiCall('/user/shop/detail', req)
        this.$set(this, 'shop', detail_res.shop)
        if (!detail_res) return false;
      }
      this.getStoreAttributeIconId()
    } catch(e) {
      console.log(e)
      this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
  methods: {
    getStoreAttributeIconId() {
      for (let i = 1; i <= 20; i++) {
        if (this.shop.store_attribute_icon_display_flags['store_attribute_icon_display_flag_' + i] === 1) {
          this.feature_icons.push(i.toString().padStart(2, '0'))
        }
      }
    },
    toHref(url) {
      var req = {
        destination: url,
        transition_type: 4,
      }
      if (!(this.screenTransition(req))) {
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
    toMap() {
      var req = {
          destination: 'https://' + process.env.VUE_APP_HOST_NAME + "/sp/info/map?shop_id" + this.init.shop_id,
          feature_id: "shopinfo",
          screen_id: "shopinfo",
          transition_type: 2,
          page_title: "店舗マップ",
      }
      if (!(this.screenTransition(req))) {
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
    async followSet(flg, shop_id){
      try {
        this.loading = true
        if(flg == 0){
          this.shop.is_follow = false
        }else{
          this.shop.is_follow = true
        }
        /* 店舗フォローAPIを叩く */
        var req = {
          'shop_id': shop_id,
          'flg': flg,
        }
        var follow_res = await this.apiCall('/user/favorite', req, 'post');
        if (!follow_res) return false;


        this.loading = false
      } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
.followbtnwrap {
  position: relative;
  height: 23px;
}
.followbtn {
  position: absolute;
  top:0;
  right: 10px;
  overflow: hidden;
  width: 89px;
  height: 23px;
}
.followbtn img {
  position: absolute;
}
.shop_details {
  line-height: 16px;
  padding: 0 1.25rem 5rem 1.25rem;
}
.carouselCtrl {
  margin: 9px 0 0 0;
}
.carouselCtrl button {
  margin: 0 0 0 5px;
  color: #9D9D9D;
  text-shadow: 0 0 4px #0000004D;
}
.carouselCtrl .active {
  color: var(--main_color) !important;
}
.shop_feature_icon {
  width: 3.5em;
  padding-right: 1em;
}
.tooltip{
  position: relative;
  cursor: pointer;
  display: inline-block;
  border-radius: 2px;
}
/* 吹き出し部分 */
.tooltip_description {
  display: none;
  position: absolute;
  padding: 0.75em;
  font-size: 0.75em;
  line-height: 1em;
  color: #a40024;
  border-radius: 5px;
  background: #fff;
  text-align: center;
}
/* 吹き出しの三角形部分 */
.tooltip_description:before {
  content: "";
  position: absolute;
  top: 20px;
  left: 5em;
  border: 14px solid transparent;
  border-top: 14px solid #fff;
  margin-left: -13px;
  transform: rotateZ(0deg);
}
.tooltip:hover .tooltip_description{
  display: inline-block;
  top: -3.5em;
  left: -3.5em;
  width: 11em;
  z-index: 20;
}
/* .shop_detail {
} */
.row {
  margin: initial;
}
.row-shop-detail {
  border-bottom: 1px solid #E6E6E6;
}
.header {
  height: 3rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
}
.v-icon {
  font-size: 1rem !important;
}

/*ICON FONT*/
@font-face {
  font-family: 'yamaya_icon';
  src: url('~@/assets/font/yamaya_icon.ttf') format('truetype'), url('~@/assets/font/yamaya_icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
[class^="i_"], [class*=" i_"] {
  font-family: 'yamaya_icon' !important;
  /*speak: none;*/
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.i_arrow_l:before {
  content: "\e900";
}
.i_arrow_r:before {
  content: "\e901";
}
.i_tel:before {
  content: "\e902";
}
.i_star:before {
  content: "\e903";
}
.i_point:before {
  content: "\e904";
}
.i_shop:before {
  content: "\e905";
}
.i_news:before {
  content: "\e906";
}
.i_menu:before {
  content: "\e907";
}
.i_map:before {
  content: "\e908";
}
.i_home:before {
  content: "\e909";
}
.i_cart:before {
  content: "\e90a";
}
.i_coupon:before {
  content: "\e90b";
}
.b_fav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  overflow: visible;
  max-width: 15rem;
  line-height: 1.5;
  color: #fff !important;
  position: relative;
  text-decoration: none;
  transition: all .2s;
  cursor: pointer;
  box-shadow: 0 1rem 0.5rem -1rem rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2rem;
  color: #fff;
  width: 3rem;
  border: 2px solid #CDCDCD;
  padding: 0.1rem 0.7rem;
  background: #CDCDCD;
  margin: 0 0.25rem;
}
.b_fav .icon::before {
  font-size: 1.4rem;
}
.b_fav.on {
  border: 2px solid #EB7178;
  background: rgba(235, 113, 120, 0.15);
}
.b_fav.on .icon::before {
  color: #EB7178;
}
</style>
